import { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { getConfig } from "../config";

export function useApiGet(url) {
  const { getAccessTokenSilently } = useAuth0();
  const [state, setState] = useState({ error: null, loading: true, data: null });
  const { apiOrigin } = getConfig();
  useEffect(() => {
    (async () => {
      try {
        // const { audience, scope, ...fetchOptions } = options;
        const accessToken = await getAccessTokenSilently();// { audience, scope });
        const res = await axios.get(`${apiOrigin}${url}`, { headers: { 'Authorization': `Bearer ${accessToken}` }});
        setState({ data: res.data, error: null, loading: false });
      } catch (error) {
        console.log(error);
        setState({ data: null, error: 'Error with api request', loading: false });
      }
    })();
  }, [apiOrigin, url, getAccessTokenSilently]);

  return { ...state };
};

export function useMakeApiGet(dispatch) {
  const { getAccessTokenSilently } = useAuth0();
  const { apiOrigin } = getConfig();
  const makeApiGet = async (url, errorMsg = 'Request encountered an error') => {
    try {
      dispatch(['REQUEST_SEND', null]);
      const accessToken = await getAccessTokenSilently();// { audience, scope });
      const { data } = await axios.get(`${apiOrigin}${url}`, { headers: { 'Authorization': `Bearer ${accessToken}` }});
      dispatch(['REQUEST_SUCCESS', data]);
      // return data;
    } catch (error) {
      console.log(error);
      dispatch(['REQUEST_ERROR', errorMsg]);
      // throw new Error('Request encountered an error');
    }
  };

  return makeApiGet;
}

export function useMakeApiPost(dispatch) {
  const { getAccessTokenSilently } = useAuth0();
  const { apiOrigin } = getConfig();
  const makeApiPost = async (url, requestData, errorMsg = 'Request encountered an error') => {
    try {
      dispatch(['REQUEST_SEND', null]);
      const accessToken = await getAccessTokenSilently();// { audience, scope });
      const { data } = await axios.post(`${apiOrigin}${url}`, requestData, { headers: { 'Authorization': `Bearer ${accessToken}` }});
      dispatch(['REQUEST_SUCCESS', data]);
      // return data;
    } catch (error) {
      console.log(error);
      dispatch(['REQUEST_ERROR', errorMsg]);
      // throw new Error('Request encountered an error');
    }
  };

  return makeApiPost;
}