import React, { Fragment, useRef } from "react";
import { useHistory } from "react-router-dom";
import { Alert, Card, CardBody, CardTitle, Button, Row, Col } from "reactstrap";
import { ReactTabulator } from 'react-tabulator';
import dayjs from 'dayjs';
import Loading from "../components/Loading";
import { useApiGet } from "../utils/apiCall";

const columns = [
  { title: "Id", field: "geotab_id", headerFilter: true, width: 90 },
  { title: "First Name", field: "first_name", headerFilter: true },
  { title: "Last Name", field: "last_name", headerFilter: true },
  { title: "Badge Id", field: "employee_number", headerFilter: true },
  { title: "Sends Punches", field: "send_punches", hozAlign: "center", formatter: "tickCross", headerFilter: true, width: 160 },
  { title: "Active", field: "is_active", hozAlign: "center", formatter: "tickCross", headerFilter: true, width: 90 },
  { title: "Username", field: "username", headerFilter: true, widthGrow: 2 },
  { title: "Created At", field: "created_at" },
  { title: "Updated At", field: "updated_at" },
];

export const GeoTabDriversComponent = () => {
  const history = useHistory();
  const { loading, error, data: drivers = [] } = useApiGet(`/api/geotab/drivers`);
  const tableRef = useRef(null);

  function handleClearFilter() {
    tableRef.current.table.clearFilter(true);
  };

  function handleDownload() {
    tableRef.current.table.download("csv", `geotab-drivers-${dayjs().format('YYYY-MM-DD_HH-mm-SS')}.csv`);
  }

  const handleDriverRowClick = (e, row) => { 
    const driverId = row.getIndex();
    history.push(`/driver/${driverId}`);
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <Fragment>
      <div className="mb-5">
        {error && (
          <Alert color="warning">
            {error}
          </Alert>
        )}
        <Card>
          <CardBody>
            <Row className="mb-2">
              <Col xs="6"><CardTitle tag="h5">GeoTab Drivers</CardTitle></Col>
              <Col xs="6">
                <div className="float-right">
                  <Button onClick={handleClearFilter} color="primary">Clear Filters</Button>{' '}
                  <Button onClick={handleDownload} color="secondary">Download</Button>
                </div>
              </Col>
            </Row>
            <ReactTabulator className="table-striped table-bordered table-sm"
              index={'geotab_id'}
              ref={tableRef}
              data={drivers}
              placeholder={'No Drivers Loaded'}
              columns={columns}
              tooltips={true}
              layout={'fitDataFill'}
              height={'500px'}
              rowClick={handleDriverRowClick}
              options={{
                downloadDataFormatter: (data) => data,
                downloadReady: (fileContents, blob) => blob,
              }}
              />
          </CardBody>
        </Card>
      </div>
    </Fragment>
  );
};

export default GeoTabDriversComponent;
