import React, { Fragment, useReducer, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { Alert, Card, CardBody, CardTitle, Button, Row, Col } from "reactstrap";
import { ReactTabulator } from 'react-tabulator';
import Loading from "../components/Loading";
import { useMakeApiGet, useMakeApiPost } from "../utils/apiCall";

const initialState = {
  loading: false,
  driverLogs: [],
  error: null, 
};

function reducer(state, [type, value]) {
  switch(type) {
    case 'REQUEST_SEND':
      return { ...state, loading: true, error: null };
    case 'REQUEST_SUCCESS':
      return { loading: false, driverLogs: value, error: null };
    case 'REQUEST_ERROR':
      return { ...state, loading: false, error: value };
    default:
      return state;
  }
}

const columns = [
  { title: "Id", field: "id", visible: false },
  { title: "GeoTab Id", field: "driver_id", headerFilter: true },
  { title: "First Name", field: "first_name", headerFilter: true },
  { title: "Last Name", field: "last_name", headerFilter: true },
  {
    title: "Status",
    field: "driver_status",
    headerFilter:"autocomplete",
    headerFilterPlaceholder: "Status...",
    headerFilterParams: {
      showListOnEmpty: true,
      freetext: true,
      values: true 
    }
  },
  { title: "Punched At", field: "entry_datetime" },
  { title: "Badge Id", field: "employee_number", headerFilter: true },
  { title: "Locked", field: "locked", hozAlign: "center", formatter: "tickCross", headerFilter: true, width: 105 },
];

const PunchesComponent = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const makeApiGet = useMakeApiGet(dispatch);
  const makeApiPost = useMakeApiPost(dispatch);
  const tableRef = useRef(null);

  useEffect(() => {
    makeApiGet(`/api/punches/lock`);
  },[]);// eslint-disable-line react-hooks/exhaustive-deps

  function handleClearFilter() {
    tableRef.current.table.clearFilter(true);
  };

  const handleLockClick = () => {
    makeApiPost(`/api/punches/lock`, {},'Error processing logs');
  };

  if(state.loading) {
    return <Loading />;
  }

  if(state.error) {
    return (
    <Fragment>
      <Alert fade={false} color="danger">{state.error}</Alert>
      <Link to="/" className="btn btn-secondary">Back</Link>
    </Fragment>
    );
  }
  return (
    <Fragment>
      <div className="mb-5">
        <Alert color="warning">
        Locking is <strong>normally handled automatically everyday</strong> and this 
        should only be used when required to process yesterdays punches sooner (i.e. payroll days).
        Logs are based off of the drivers HOS from GeoTab and change frequently. By locking these logs we stop all 
        changes and begin the process of creating and sending these to ADP.<br />The creation and sending of time punches 
        is an automated process that <strong>runs once an hour at 10 after the hour</strong> so please allow 
        some time after locking the logs.
        </Alert>
      <Card>
        <CardBody>
          <Row className="mb-2">
            <Col xs="6"><CardTitle tag="h5">Time Logs</CardTitle></Col>
            <Col xs="6">
              <div className="float-right">
                <Button onClick={handleClearFilter} color="primary">Clear Filters</Button>{' '}
                <Button onClick={handleLockClick} color="danger">Lock Logs</Button>
              </div>
            </Col>
          </Row>
          <ReactTabulator className="table-striped table-bordered table-sm"
            index={'id'}
            ref={tableRef}
            data={state.driverLogs}
            placeholder={'Empty'}
            columns={columns}
            tooltips={true}
            layout={'fitDataFill'}
            height={'500px'}
            selectable={false}
            />
        </CardBody>
      </Card>
    </div>
  </Fragment>
  );
};

export default PunchesComponent;