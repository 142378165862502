import React from "react";
import { Spinner } from 'reactstrap';
// import loading from "../assets/loading.svg";

// const Loading = () => (
//   <div className="spinner">
//     <img src={loading} alt="Loading" />
//   </div>
// );

const Loading = () => (
<div className="spinner">
  <Spinner style={{ width: '3rem', height: '3rem' }} />{' '}
</div>);

export default Loading;
