import React from "react";
import { Alert, Card, CardBody, CardTitle } from "reactstrap";

const AdpInfo = (props) => {
  if(!props || !props.associate_oid) {
    return (
      <Alert color="danger">
        The ADP profile was not found for this GeoTab user. We use the employee number from GeoTab to match the Badge Id in ADP. 
        Please make sure that this employee exists in ADP and that the correct employee number was entered in GeoTab.
      </Alert>
    );
  }
  return (
    <Card>
      <CardBody>
        <CardTitle tag="h5">ADP Info</CardTitle>
        <div>
          <span><strong>Assoc. Id: </strong>{props.associate_oid}</span><br />
          <span><strong>Pos. Id: </strong>{props.position_id}</span><br />
          <span><strong>Badge Id: </strong>{props.badge_id}</span><br />
          <span><strong>Name: </strong>{props.aw_last_name}, {props.aw_first_name} {props.aw_middle_name}</span><br />
          <span><strong>Status: </strong>{props.status}</span><br />
          <span><strong>Exempt Status: </strong>{props.exemption_status}</span><br />
          <span><strong>Dept: </strong>{props.department}</span><br />
          <span><strong>Is Driver: </strong>{props.is_driver ? 'Yes' : 'No'}</span><br />
          <span><strong>Created At: </strong>{props.aw_created_at}</span><br />
          <span><strong>Updated At: </strong>{props.aw_updated_at}</span><br />
        </div>
      </CardBody>
    </Card>
  );
};

export default AdpInfo;