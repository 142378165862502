import React, { Fragment, useRef } from "react";
import { Alert, Card, CardBody, CardTitle, Button, Row, Col } from "reactstrap";
import { ReactTabulator } from 'react-tabulator';
import dayjs from 'dayjs';
import Loading from "../components/Loading";
import { useApiGet } from "../utils/apiCall";

const columns = [
  { title: "Id", field: "id", visible: false },
  { title: "GeoTab Id", field: "geotab_id", headerFilter: true },
  { title: "First Name", field: "first_name", headerFilter: true },
  { title: "Middle Name", field: "middle_name", headerFilter: true },
  { title: "Last Name", field: "last_name", headerFilter: true },
  { title: "Punch Id", field: "driver_punch_id", visible: false },
  {
    title: "Action",
    field: "action",
    headerFilter:"autocomplete",
    headerFilterPlaceholder: "Action...",
    headerFilterParams: {
      showListOnEmpty: true,
      freetext: true,
      values: true 
    }
  },
  { title: "Punched At", field: "entry_datetime" },
  { title: "Sent", field: "has_sent", hozAlign: "center", formatter: "tickCross", headerFilter: true, width: 105 },
  { title: "Error", field: "res_error" },
  { title: "Badge Id", field: "badge_id", headerFilter: true },
  { 
    title: "Department",
    field: "department",
    headerFilter:"autocomplete",
    headerFilterPlaceholder: "Dept...",
    headerFilterParams: {
      showListOnEmpty: true,
      freetext: true,
      values: true 
    }
  },
  { title: "Assoc. Id", field: "associate_oid", headerFilter: true, width: 180 },
  { title: "Position Id", field: "position_id", headerFilter: true },
  {
    title: "Adp Status",
    field: "adp_status",
    headerFilter:"autocomplete",
    headerFilterPlaceholder: "Status...",
    headerFilterParams: {
      showListOnEmpty: true,
      freetext: true,
      values: true 
    }
  },
  {
    title: "Exempt Status",
    field: "exemption_status",
    editor:false,
    headerFilter:"autocomplete",
    headerFilterPlaceholder: "Status...",
    headerFilterParams: {
      showListOnEmpty: true,
      freetext: true,
      values: true 
    }
  },
  { title: "Is Driver", field: "is_driver", hozAlign: "center", formatter: "tickCross", headerFilter: true, width: 105 },
  { title: "GeoTab Active", field: "geotab_active", hozAlign: "center", formatter: "tickCross", headerFilter: true },
  { title: "Send Punches", field: "send_punches", hozAlign: "center", formatter: "tickCross", headerFilter: true },
  { title: "Created At", field: "created_at" },
  { title: "Updated At", field: "updated_at" },
];

const PunchesComponent = () => {
  const { loading, error, data: punches = [] } = useApiGet('/api/punches');
  const tableRef = useRef(null);

  function handleClearFilter() {
    tableRef.current.table.clearFilter(true);
  };

  function handleDownload() {
    tableRef.current.table.download("csv", `time-punches-${dayjs().format('YYYY-MM-DD_HH-mm-SS')}.csv`);
  }

  if (loading) {
    return <Loading />;
  }

  return (
    <Fragment>
      <div className="mb-5">
        {error && (
          <Alert color="warning">
            {error}
          </Alert>
        )}
        <Card>
          <CardBody>
            <Row className="mb-2">
              <Col xs="6"><CardTitle tag="h5">Time Punches</CardTitle></Col>
              <Col xs="6">
                <div className="float-right">
                  <Button onClick={handleClearFilter} color="primary">Clear Filters</Button>{' '}
                  <Button onClick={handleDownload} color="secondary">Download</Button>
                </div>
              </Col>
            </Row>
            <ReactTabulator className="table-striped table-bordered table-sm"
              index={'id'}
              ref={tableRef}
              data={punches}
              placeholder={'Empty'}
              columns={columns}
              tooltips={true}
              layout={'fitDataFill'}
              height={'500px'}
              selectable={false}
              options={{
                downloadDataFormatter: (data) => data,
                downloadReady: (fileContents, blob) => blob,
              }}
              />
          </CardBody>
        </Card>
      </div>
    </Fragment>
  );
};

export default PunchesComponent;
