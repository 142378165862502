import React, { Fragment } from "react";
import { Row, Col, Badge } from "reactstrap";

const HeaderRow = ({ geotab_id, first_name, last_name, send_punches }) => {
  return !geotab_id ? (<Row className="mb-2"></Row>)
  : ( 
    <Row className="mb-2">
      {send_punches && (
        <Fragment>
          <Col xs="6"><h3>{first_name} {last_name}</h3><h5><Badge color="success" pill>Connected</Badge></h5></Col>
          <Col xs="6"></Col>
        </Fragment>
      )}
      {!send_punches && (
        <Fragment>
          <Col xs="6"><h3>{first_name} {last_name}</h3><h5><Badge className="align-middle" color="danger" pill>Not Connected</Badge></h5></Col>
          <Col xs="6"></Col>
        </Fragment>
      )}
    </Row>
  );
};

export default HeaderRow;