import React from "react";
import { Alert, Card, CardBody, CardTitle } from "reactstrap";

const GeotabInfo = (props) => {
  if(!props || !props.geotab_id) {
    return (<Alert color="danger">
      The GeoTab profile was not found.
    </Alert>);
  }

  return (
    <Card>
      <CardBody>
        <CardTitle tag="h5">GeoTab Info</CardTitle>
        <div>
          <span><strong>GeoTab props Id: </strong>{props.geotab_id}</span><br />
          <span><strong>Username: </strong>{props.username}</span><br />
          <span><strong>Name: </strong>{props.last_name}, {props.first_name}</span><br />
          <span><strong>Emp Num: </strong>{props.employee_number}</span><br />
          <span><strong>Active: </strong>{props.is_active ? 'Yes' : 'No'}</span><br />
          <span><strong>Active Dates: </strong>{props.activeFrom} - {props.activeTo}</span><br />
          <span><strong>State: </strong>{props.state}</span><br />
          <span><strong>Notes: </strong>{props.notes}</span><br />
          <span><strong>Created At: </strong>{props.created_at}</span><br />
          <span><strong>Updated At: </strong>{props.updated_at}</span><br />
        </div>
      </CardBody>
    </Card>
  );
};

export default GeotabInfo;