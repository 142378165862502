import React, { Fragment, useReducer, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { Row, Col, Card, CardBody, Alert } from "reactstrap";
import Loading from "../components/Loading";
import HeaderRow from "./HeaderRow";
import FooterRow from "./FooterRow";
import AdpInfo from "./AdpInfo";
import GeotabInfo from "./GeotabInfo";
import { useMakeApiGet, useMakeApiPost } from "../utils/apiCall";

const initialState = {
  loading: true,
  driver: {},
  error: null, 
};

function reducer(state, [type, value]) {
  switch(type) {
    case 'REQUEST_SEND':
      return { ...state, loading: true, error: null };
    case 'REQUEST_SUCCESS':
      return { loading: false, driver: value, error: null };
    case 'REQUEST_ERROR':
      return { ...state, loading: false, error: value };
    default:
      return state;
  }
}

const DriverComponent = () => {
  let { driverId } = useParams();
  const [state, dispatch] = useReducer(reducer, initialState);
  const makeApiGet = useMakeApiGet(dispatch);
  const makeApiPost = useMakeApiPost(dispatch);
  
  useEffect(() => {
    makeApiGet(`/api/driver/${driverId}`);
  },[driverId]);// eslint-disable-line react-hooks/exhaustive-deps

  const handleConnect = (geotab_id) => {
    makeApiPost(`/api/driver/${geotab_id}`, { sendPunches: true },'Error connecting driver');
  };

  const handleDisconnect = (geotab_id) => {
    makeApiPost(`/api/driver/${geotab_id}`, { sendPunches: false },'Error disconnecting driver');
  };

  if(state.loading) {
    return <Loading />;
  }

  if(state.error) {
    return (
    <Fragment>
      <Alert fade={false} color="danger">{state.error}</Alert>
      <Link to="/geotab/drivers" className="btn btn-secondary">Back</Link>
    </Fragment>
    );
  }

  return (
    <Fragment>
      <Card>
        <CardBody>
          <HeaderRow {...state.driver} />
          <Row>
            <Col xs="6"><GeotabInfo {...state.driver} /></Col>
            <Col xs="6"><AdpInfo {...state.driver} /></Col>
          </Row>
          <Row className="mt-2">
            <Col xs="6">
            <Alert fade={false} color="info">Connecting the driver means that we will start taking their Hours of Service (HOS) time from GeoTab
                and use that to calculate their Clockin/Clockout times in Adp. In order for this to be possible, 
                the <strong>GeoTab Employee Number</strong> <u>must match</u> the <strong>Adp Badge Id</strong>. Other 
                considerations are that the driver should be <strong>Active</strong> in both systems, and <strong>NON-EXEMPT</strong> in Adp.</Alert>
            </Col>
          </Row>
          <FooterRow {...state.driver} handleConnectFn={handleConnect.bind(this)} handleDisconnectFn={handleDisconnect.bind(this)} />
        </CardBody>
      </Card>
    </Fragment>
  );
};

export default DriverComponent;
