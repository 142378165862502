import React, { Fragment } from "react";
import { Link } from 'react-router-dom';

const AuthContent = () => {
  return (
    <Fragment>
      <div className="row justify-content-md-center">   
        <div className="col">
          <div className="card">
            <h5 className="card-header">GeoTab Drivers Import</h5>
            <div className="card-body">
              <h6 className="card-title">Manage Connected Drivers!</h6>
              <p className="card-text">Here you can select a driver and see if they are ready to be connected. If we have a
              driver with a matching <strong>Adp Badge Id</strong> in their <strong>GeoTab Employee Number</strong>, you 
              will be able to see driver information from both systems.</p>
              <Link to="/geotab/drivers" className="btn btn-primary">View GeoTab Drivers</Link>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="card">
            <h5 className="card-header">Adp Workers Import</h5>
            <div className="card-body">
              <h6 className="card-title">View Adp Information!</h6>
              <p className="card-text">Here you can view the information that we have received from Adp. If 
              a driver is not linked in the GeoTab list, you can come here to search to see if they are setup 
              correctly in Adp or if the <strong>Badge Id</strong> is different.</p>
              <Link to="/adp/workers" className="btn btn-primary">View Adp Workers</Link>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="card">
            <h5 className="card-header">Punches Sent To Adp</h5>
            <div className="card-body">
              <h6 className="card-title">See What We Sent!</h6>
              <p className="card-text">Here you can see the time punches that we calculated and their 
              status. This allows you to compare what we got from GeoTab, what we sent to Adp, and what Adp accepted.</p>
              <Link to="/punches" className="btn btn-primary">View Sent Punches</Link>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="card">
            <h5 className="card-header">Process Current Logs</h5>
            <div className="card-body">
              <h6 className="card-title">Need to close out the week?</h6>
              <p className="card-text">Here you can see the logs that need to be locked and processed. This
              is <strong>normally handled automatically everyday</strong>, but on payroll days this can be used to process 
              yesterdays logs sooner.</p>
              <Link to="/lock" className="btn btn-primary">View Logs To Process</Link>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default AuthContent;