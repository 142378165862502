import React from "react";
import { Router, Route, Switch } from "react-router-dom";
import { Container } from "reactstrap";

import Loading from "./components/Loading";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import Home from "./home/Home";
import Profile from "./profile/Profile";
import GeoTabDrivers from "./geotab/GeoTabDrivers";
import AdpWorkers from "./adp/AdpWorkers";
import Driver from "./driver/Driver";
import Punches from "./punches/Punches";
import LockPunches from "./punches/LockPunches";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import history from "./utils/history";

// styles
import "./App.css";

// fontawesome
import initFontAwesome from "./utils/initFontAwesome";
initFontAwesome();

const ProtectedRoute = ({ component, ...args }) => (
  <Route component={withAuthenticationRequired(component,{onRedirecting: () => <Loading />})} {...args} />
);

const App = () => {
  const { isLoading, error } = useAuth0();

  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Router history={history}>
      <div id="app" className="d-flex flex-column h-100">
        <NavBar />
        <Container fluid={true} className="flex-grow-1 mt-3">
          <Switch>
            <Route path="/" exact component={Home} />
            <ProtectedRoute path="/profile" component={Profile} />
            <ProtectedRoute path="/geotab/drivers" component={GeoTabDrivers} />
            <ProtectedRoute path="/driver/:driverId" component={Driver} />
            <ProtectedRoute path="/adp/workers" component={AdpWorkers} />
            <ProtectedRoute path="/punches" component={Punches} />
            <ProtectedRoute path="/lock" component={LockPunches} />
          </Switch>
        </Container>
        <Footer />
      </div>
    </Router>
  );
};

export default App;
