import React, { Fragment } from "react";
import { Row, Col, Button } from "reactstrap";
import { Link } from "react-router-dom";

const FooterRow = ({ geotab_id, send_punches, badge_id, employee_number, is_active, status, handleConnectFn, handleDisconnectFn }) => {
  // Disable Connect: emp num and badge id dont match, either status is inactive
  const canConnect = badge_id === employee_number && is_active && status === 'Active';
  return !geotab_id ? (<Row className="mt-2"></Row>)
  : ( 
    <Row>
      <Fragment>
        <Col xs="6">
          <Link to="/geotab/drivers" className="btn btn-secondary">Back</Link>{' '}
          {send_punches && (<Button onClick={() => handleDisconnectFn(geotab_id)} color="danger">Disconnect</Button>)}
          {!send_punches && (<Button onClick={() => handleConnectFn(geotab_id)} color="success" disabled={!canConnect}>Connect</Button>)}
        </Col>
        <Col xs="6"></Col>
      </Fragment>   
    </Row>
  );
};

export default FooterRow;