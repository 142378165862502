import React, { useState } from "react";
import { NavLink as RouterNavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAuth0 } from "@auth0/auth0-react";
import {
  Collapse,
  Container,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

const NavBar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const {
    user, isAuthenticated, loginWithRedirect, logout,
  } = useAuth0();
  const toggle = () => setIsOpen(!isOpen);
  const logoutWithRedirect = () => logout({ returnTo: window.location.origin });

  return (
    <div className="nav-container">
      <Navbar color="light" light={true} expand="md">
        <Container>
          <NavbarBrand className="logo" />
          <NavbarToggler onClick={toggle} />
          <Collapse isOpen={isOpen} navbar>
            <Nav className="mr-auto" navbar>
              <NavItem>
                <NavLink tag={RouterNavLink} to="/" exact>Home</NavLink>
              </NavItem>
              {isAuthenticated && (
                <NavItem>
                  <NavLink tag={RouterNavLink} to="/geotab/drivers" exact>GeoTab Drivers</NavLink>
                </NavItem>
              )}
              {isAuthenticated && (
                <NavItem>
                  <NavLink tag={RouterNavLink} to="/adp/workers" exact>ADP Workers</NavLink>
                </NavItem>
              )}
              {isAuthenticated && (
                <NavItem>
                  <NavLink tag={RouterNavLink} to="/punches" exact>Sent Punches</NavLink>
                </NavItem>
              )}
            </Nav>
            
            <Nav className="d-none d-md-block" navbar>
              {!isAuthenticated && (
                <NavItem>
                  <Button color="primary" className="btn-margin" onClick={() => loginWithRedirect()}>Log in</Button>
                </NavItem>
              )}
              {isAuthenticated && (
                <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle nav caret>
                    <img src={user.picture} alt="Profile" className="rounded-circle" width="35"/>
                  </DropdownToggle>
                  <DropdownMenu right>
                    <DropdownItem header>{user.name}</DropdownItem>
                    <DropdownItem tag={RouterNavLink} to="/profile">
                      <FontAwesomeIcon icon="user" className="mr-3" /> Profile
                    </DropdownItem>
                    <DropdownItem onClick={() => logoutWithRedirect()}>
                      <FontAwesomeIcon icon="power-off" className="mr-3" /> Log out
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              )}
            </Nav>
            {!isAuthenticated && (
              <Nav className="d-md-none" navbar>
                <NavItem>
                  <Button color="primary" block onClick={() => loginWithRedirect({})}>Log in</Button>
                </NavItem>
              </Nav>
            )}
            {isAuthenticated && (
              <Nav className="d-md-none" navbar>
                <NavItem>
                  <NavLink tag={RouterNavLink} to="/profile" exact>Profile</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink tag={RouterNavLink} to="#" onClick={() => logoutWithRedirect()} exact>Log Out</NavLink>
                </NavItem>
              </Nav>
            )}
          </Collapse>
        </Container>
      </Navbar>
    </div>
  );
};

export default NavBar;
