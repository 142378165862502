import React, { Fragment } from "react";
import { Button } from "reactstrap";

const NonAuthContent = ({ loginWithRedirect }) => {
  return (
    <Fragment>
      <div className="row justify-content-md-center">
        <div className="col col-lg-2"></div>
        <div className="col-md-auto">
          <p className="lead">Login to configure driver settings</p>
          <Button color="primary" block onClick={() => loginWithRedirect({})}>Log in</Button>
        </div>
        <div className="col col-lg-2"></div>
      </div>
    </Fragment>
  );
}

export default NonAuthContent;