import React, { Fragment } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import NonAuthContent from './NonAuthContent';
import AuthContent from './AuthContent';

const Home = () => {
  const { isAuthenticated, loginWithRedirect } = useAuth0();
  return (
  <Fragment>
    <div className="text-center hero my-2">
      <h1 className="mb-4">Welcome to Corrigan Driver Portal</h1>
      {!isAuthenticated && <NonAuthContent loginWithRedirect={loginWithRedirect} />}
      {isAuthenticated && <AuthContent />}
    </div>
  </Fragment>);
};

export default Home;
