require('dotenv').config();

export function getConfig() {
  return {
    apiOrigin: process.env.REACT_APP_API_ORIGIN || `http://localhost:${process.env.REACT_APP_API_PORT || 3000}`,
    domain: process.env.REACT_APP_AUTH0_DOMAIN,
    clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
    audience: process.env.REACT_APP_AUTH0_AUDIENCE,
  };
}
